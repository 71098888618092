import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from "prop-types"

const SEO = ({ postData, pageTitle, pageDescription, pageImage, lang }) => (

  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author {
              name
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const postMeta = postData || {};
      const { frontmatter, excerpt } = postMeta

      let title = `${pageTitle || seo.title} - Kalukanda House`
      let description = `${pageDescription || seo.description} - Kalukanda House`

      if(frontmatter){
        title = frontmatter.title ? `${frontmatter.title} - Kalukanda House` : seo.title
        description = frontmatter.description || excerpt || seo.description
      }

      const image = pageImage ? `${seo.siteUrl}${pageImage}` : `${seo.siteUrl}${seo.image}`;
      const url = postMeta.slug
        ? `${seo.siteUrl}/${postMeta.slug}/`
        : seo.siteUrl;
      const datePublished = postMeta.datePublished || false;

      return (
        <React.Fragment>
          <Helmet htmlAttributes={{
              lang
            }}>
            {/* General tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <link rel="canonical" href={url} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="fb:app_id" content={seo.social.fbAppID} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={seo.social.twitter} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </Helmet>
        </React.Fragment>
      );
    }}
  />
)

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}


export default SEO